import React, { ReactNode, useEffect, useRef, useState } from 'react';
import HTMLFlipBook from 'react-pageflip';

interface PageProps {
  children?: ReactNode;
  number: number;

}

interface PageCoverProps { children?: ReactNode; }

const PageCover = React.forwardRef<HTMLDivElement, PageCoverProps>((props, ref) => { return ( 
<div className="page page-cover" ref={ref} data-density="hard"> 
    <div className="page-content"> 
        <div className='cloud'>
            <div className='circle'></div>
            <div className='circle'></div>
            <div className='circle'></div>
            <div className='circle bottom'></div>
            <div className='circle bottom'></div>
            <div className='circle bottom'></div>          
            <div className='circle bottom'></div>
            <div className='circle bottom'></div>
            <div className='circle bottom'></div>       
            <div className='circle bottom'></div>
            <div className='circle bottom'></div>
            <div className='circle bottom'></div>     
              
        </div>
        <h2 className='title'>{props.children}</h2> 
        <br/>
    </div> 
</div> ); });

const Page = React.forwardRef<HTMLDivElement, PageProps>(({ children, number }, ref) => {
  return (
    <div className={`book-page`} ref={ref}>
      {children}
    </div>
  );
});

const Book: React.FC = () => {

  return (
    <>
      <HTMLFlipBook
        width={540} 
        height={720} 
        usePortrait={false}
        maxShadowOpacity={0.5}
        showCover={true}
        mobileScrollSupport={false}
        orientation="landscape"
      >
        <PageCover>Adriana <br/>❤️ <br/>Gustavo <br/> 1 Ano</PageCover>
        {/* <Page number={1}></Page>
        <Page number={2}>
            <div className='pagina-1'>
            </div>
            <div className='pagina-1-img'>
                <img src={`${process.env.PUBLIC_URL}/fotos/foto0.png`}/>   
            </div>
            <div className='pagina-1-text'>
                Primeiro Encontro<br/>
                23.09.2023
            </div>
        </Page>
        <Page number={3}></Page>
        <Page number={4}>
            <div className='pagina-2'>
                Nosso namoro oficial começou dia 02.12.2023, lembro como se fosse ontem, estavamos no Ceret quando te pedi em namoro oficialmente! Foi incrível, logo na sequência fomos curtir o início do nosso namoro no Guarujá!
                <div className='pagina-2-img'>
                    <img src={`${process.env.PUBLIC_URL}/fotos/foto1.png`}/>   
                </div>
            </div>
        </Page> */}
        <Page number={1}></Page>
        <Page number={2}>
        <div className='pagina-1'></div>            
        </Page>          
        <Page number={3}></Page>
        <Page number={4}>
        <div className='pagina-2'></div>            
        </Page>          
        <Page number={5}></Page>
        <Page number={6}>
        <div className='pagina-3'></div>            
        </Page>        
        <Page number={7}></Page>
        <Page number={8}>
        <div className='pagina-4'></div>      
        </Page>
        <Page number={9}></Page>
        <Page number={10}>
        <div className='pagina-5'></div>      
        </Page>
        <Page number={11}></Page>
        <Page number={12}>
        <div className='pagina-6'></div>      
        </Page>     
        <Page number={13}></Page>
        <Page number={14}>
        <div className='pagina-7'></div>      
        </Page>          
        <Page number={15}></Page>
        <Page number={16}>
        <div className='pagina-8'></div>      
        </Page>        
        <Page number={17}></Page>
        <Page number={18}>
        <div className='pagina-9'></div>      
        </Page>           
        <Page number={18}></Page>
        <Page number={19}>
        <div className='pagina-10'></div>      
        </Page>     
        <Page number={20}></Page>
        <Page number={21}>
        <div className='pagina-11'></div>      
        </Page> 
        <Page number={22}></Page>
        <Page number={23}>
        <div className='pagina-12'></div>      
        </Page>     
        <Page number={24}></Page>
        <Page number={25}>
        <div className='pagina-13'></div>      
        </Page>     
        <Page number={26}></Page>
        <Page number={27}>
        <div className='pagina-14'></div>      
        </Page>      
        <Page number={28}></Page>
        <Page number={29}>
        <div className='pagina-15'></div>      
        </Page>            
        <Page number={30}></Page>
        <Page number={31}>
        <div className='pagina-17'></div>      
        </Page>     
        <Page number={32}></Page>
        <Page number={33}>
        <div className='pagina-18'></div>      
        </Page>                                                                                        
      </HTMLFlipBook>
    </>
  );
}

export default Book;
